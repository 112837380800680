import React, { useEffect, useState } from 'react'
import { SkPage, SkPageLoader } from '@core/components/page'
import { referentialStore } from '@common/models/referential/referentialStore'
import { CodeBundleEnum } from '@common/global/enums'
import { rootStore } from '@/stores/rootStore'
import { reaction } from 'mobx'
import dynamic from 'next/dynamic'

const SkDashboardRestaurant = dynamic(() => import('@/components/dashboardRestaurant/skDashboardRestaurant'), { ssr: false })
const SkDashboard = dynamic(() => import('@/components/dashboard/skDashboard'), { ssr: false })
const SkTenantList = dynamic(() => import('@/components/tenant/list/skTenantList'), { ssr: false })

function Page() {
  return (
    <SkPage>
      <WelcomePage />
    </SkPage>
  )
}

export default Page

function WelcomePage() {
  const [loading, setLoading] = useState(!rootStore.menuStore?.hasLinkPath('/'))
  const tenantProperties = referentialStore.data.tenantProperties

  const checkIfHomepageIsAvailable = () => {
    if (!rootStore.menuStore.hasLinkPath('/')) {
      rootStore.menuStore.gotoFirstPageAvailable()
    } else {
      setLoading(false)
    }
  }

  useEffect(() => {
    let disposerValueModified: any = null
    disposerValueModified = reaction(
      () => rootStore.menuStore.links,
      () => {
        checkIfHomepageIsAvailable()
        disposerValueModified()
      }
    )
    checkIfHomepageIsAvailable()
  }, [])

  if (loading) return <SkPageLoader />
  if (!tenantProperties) return <SkTenantList />
  if (tenantProperties.hasBundle(CodeBundleEnum.restaurant)) return <SkDashboardRestaurant />
  if (tenantProperties.hasBundle(CodeBundleEnum.stock)) return <SkDashboard />
}
